export const constants = {
    app: "DARTCE",
    title: "DARTce",
    tagline: "The Cloud Extension to the DART portal.",
    release_month: null,
    theme: "dartce",
    loading: {
        background_color: "#deeeff"
    },
    report_list: "reports.dartce.json",
    faq_url: false,
    feedback_url: false,
    overwrite_roles_by_icao: true,
    profile_page: false,
    log_page: false,
    assets_path: "https://origins-assets.nextcloud.aero/reportconfig-images/dartce/",
    cloudbar: false
};
  